import React from 'react';
import { CommonForm } from 'Components';
import { components } from 'Common';
import { checkRequired } from 'Helpers/validators';
const { FormGroup } = components;

export class UserForm extends CommonForm {
  modelName = 'user';

  defaultItem = {
    email: '',
    first_name: '',
    last_name: '',
    organization: '',
    address: '',
    phone: '',
  }

  validators = {
    email: [checkRequired('email')],
    address: [checkRequired('address')],
    phone: [checkRequired('phone')],
  }


  handleChangeEmail = ({ target }) => this.changeValue('email', target.value);
  handleChangeFirstName = ({ target }) => this.changeValue('first_name', target.value);
  handleChangeLastName = ({ target }) => this.changeValue('last_name', target.value);
  handleChangeOrganization = ({ target }) => this.changeValue('organization', target.value);
  handleChangeAddress = ({ target }) => this.changeValue('address', target.value);
  handleChangePhoneNumber = ({ target }) => this.changeValue('phone', target.value);

  get body() {
    const { item } = this.state;

    return (
      <div className="card card--size-md">
        <FormGroup label="Email" isRequired
          validationMessages={this.validationMessages.email}>
          <input type="text" className="input-group__input" value={item.email}
            onChange={this.handleChangeEmail} />
        </FormGroup>
        <FormGroup label="Address" isRequired
        validationMessages={this.validationMessages.address}>
          <input type="text" className="input-group__input" value={item.address}
            onChange={this.handleChangeAddress} />
        </FormGroup>
        <FormGroup label="Phone number" isRequired
        validationMessages={this.validationMessages.phone}>
          <input type="text" className="input-group__input" value={item.phone}
            onChange={this.handleChangePhoneNumber} />
        </FormGroup>
        <FormGroup label="First Name">
          <input type="text" className="input-group__input" value={item.first_name}
            onChange={this.handleChangeFirstName} />
        </FormGroup>
        <FormGroup label="Last Name">
          <input type="text" className="input-group__input" value={item.last_name}
            onChange={this.handleChangeLastName} />
        </FormGroup>
        <FormGroup label="Organization">
          <input type="text" className="input-group__input" value={item.organization}
            onChange={this.handleChangeOrganization} />
        </FormGroup>
        {this.formFooter}
      </div>
    );
  }
}

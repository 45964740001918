import React, { Fragment, } from 'react';
import { CommonList, } from 'Components';
import { TeamForm } from './form';


export class Team extends CommonList {
  static defaultProps = {
    ...CommonList.defaultProps,
    hideDeleteButton: true,
  };

  get tableConfig() {
    const baseConfig = [
      {
        title: 'Role',
        accessor: 'role',
      },
      {
        title: 'Name',
        accessor: 'name',
      },
      ...(this.props.locked && !this.props.is_current_user_admin ? [] : [this.actionsColumn]),
      ...(this.props.shirts
        ? [
            {
              title: 'Shirt Style',
              accessor: 'shirt_style',
            },
            {
              title: 'Shirt Size',
              accessor: 'shirt_size',
            },
          ]
        : []),
    ];

    return baseConfig;
  }
  get addons() {
    return (
      <Fragment>
        <div className="complete-mark mb-20">
            <span className="complete-mark__text">Please ensure that the Team name, Preferred start and Captain name are filled in.</span>
        </div>
        <TeamForm {...this.props} />
      </Fragment>
    );
  }
}

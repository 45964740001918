import React, { Fragment } from 'react';
import { CommonForm } from 'Components';
import { components } from 'Common';
import RadioOptionGroup from 'Components/radioOptionGroup';

export class TeamMemberForm extends CommonForm {
  modelName = 'team_member';

  radioOptions = [
    {
      valueForActive: 'am/pm',
      value: 'am/pm',
      text: 'AM/PM'
    },
    {
      valueForActive: 'pm/am',
      value: 'pm/am',
      text: 'PM/AM'
    },
  ]

  state = {
    ...this.state,
    isEditMode: false,
  }

  afterSubmit = () => {
    this.props.fetchData();
  }

  handleChangeFirstName = ({ target }) => this.changeValue('first_name', target.value);
  handleChangeLastName = ({ target }) => this.changeValue('last_name', target.value);
  handleChangeRole = ({ target }) => this.changeValue('role', target.value);
  handleChangeShirtStyle = ({ target }) => this.changeValue('shirt_style', target.value);
  handleChangeShirtSize = ({ target }) => this.changeValue('shirt_size', target.value);
  handleChangePreferredStart = ({ target }) => this.changeValue('preferred_start', target.value);
  handleChangeStartingHole = ({ target }) => this.changeValue('starting_hole', target.value);
  handleChangePosition = ({ target }) => this.changeValue('position', target.value);

  handleSetEditMode = () => this.setState({ isEditMode: true });
  handleCancelEditMode = () => {
    this.setState({ isEditMode: false, item: { ...this.props.team_member } });
  }

  get serializedItem() {
    const { role, first_name, last_name, starting_hole, position } = this.state.item

    return {
      role, first_name, last_name, starting_hole, position
    }
  }

  render() {
    const { item, isEditMode } = this.state;

    return (
      <form className="table__tr" onSubmit={this.handleSubmit}>
        <div className="table__td">
          <a href={`/teams/${item.team_id}`} className="link" target="_blank">{item.team_name}</a>
        </div>
        <div className="table__td">
          <select className="input-group__input" value={item.role}
            onChange={this.handleChangeRole} disabled={!isEditMode}>
            <option hidden></option>
            {this.props.roles.map(({ id, name }) => (
              <option key={id} value={id}>{name}</option>
            ))}
          </select>
        </div>
        <div className="table__td">
          <input type="text" className="input-group__input"
            value={item.first_name} onChange={this.handleChangeFirstName}
            disabled={!isEditMode} />
        </div>
        <div className="table__td">
          <input type="text" className="input-group__input"
            value={item.last_name} onChange={this.handleChangeLastName}
            disabled={!isEditMode} />
        </div>
        <div className="table__td">
          <RadioOptionGroup onChange={this.handleChangePreferredStart}
            value={item.preferred_start} radioOptions={this.radioOptions}
            disabled className="flex-actions--collumn" />
        </div>
        <div className="table__td">
          {item.membership}
        </div>
        <div className="table__td">
          <input type="number" className="input-group__input"
            value={item.starting_hole || ''} onChange={this.handleChangeStartingHole}
            disabled={!isEditMode} />
        </div>
        <div className="table__td">
          <input type="text" className="input-group__input"
            value={item.position || ''} onChange={this.handleChangePosition}
            disabled={!isEditMode} />
        </div>

        <div className="table__td actions">
          <div className="table__actions icon-buttons">
            {!isEditMode && (
              <button className="icon-button icon-buttons__button" type="button"
                onClick={this.handleSetEditMode}>
                <i className="fas fa-edit"></i>
              </button>
            )}
            {isEditMode && (
              <Fragment>
                <button className="icon-button icon-buttons__button" type="button"
                  onClick={this.handleCancelEditMode}>
                  <i className="fa fa-undo"></i>
                </button>
                <button className="icon-button icon-button--green icon-buttons__button"
                  type="submit">
                  <i className="fa fa-save"></i>
                </button>
              </Fragment>
            )}

          </div>
        </div>
      </form>
    )
  }
}

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { helpers } from 'Common';
import { notifyOnFailedResponse } from 'Helpers/notifications';

const { AJAX, setObjectValue } = helpers;

export class CommonForm extends Component {

  static propTypes = {
    onFormInvalid: PropTypes.func,
    api_url: PropTypes.string,
  };

  state = {
    submitted: false,
    submitDisabled: false,
    pending: false,
    item: {},
    serverErrors: {},
  };
  modelName = '';
  additionParams = {};
  additionData = null;
  validators = {};
  defaultItem = {};
  createMethod = 'post';
  updateMethod = 'put';
  form = createRef();

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setInitialData.call(this);
  }

  setInitialData() {
    this.setItem({ ...this.defaultItem, ...this.props[this.modelName] });
  }

  setItem(item, callback) {
    this.setState({ item }, callback);
  }

  setSubmitted = () => this.setState({ submitted: true });
  setUnsubmited = () => this.setState({ submitted: false });

  setServerErrors = (errors) => {
    const serverErrors = (typeof errors === 'string') ? JSON.parse(errors) : errors;
    this.setState({ serverErrors });
  };

  changeValue = (key, value, callback) => {
    const { item, serverErrors } = this.state;
    if (serverErrors[key]) {
      delete serverErrors[key];
    }
    this.setState({ item: setObjectValue(item, key, value), serverErrors, }, callback);
  }

  changeNumValue = (key, value, callback) => this.changeValue(key, Number(value), callback);

  handleSubmit(e) {
    if (!!e && !!e.preventDefault) {
      e.preventDefault();
    }
    if (!!this.state.submitDisabled) {
      return;
    }
    this.setSubmitted();
    if (!this.isFormValid && !!this.props.onFormInvalid) {
      this.props.onFormInvalid(this.validationMessages);
      return;
    }
    const serializedItem = this.serializedItem;
    const { id } = this.state.item;
    const method = (id === undefined || id === null) ? this.createMethod : this.updateMethod;
    const urlToSend = method === this.createMethod ? this.apiUrl : `${this.apiUrl}/${id}`;
    let body = !!this.modelName ? { [this.modelName]: serializedItem } : serializedItem;
    if (!!this.additionData) {
      body = { ...body, ...this.additionData };
    }
    this.setState({ pending: true });
    return AJAX[method](urlToSend, {
      body,
      ...this.additionParams
    }).then((data) => {
      this.setState({ pending: false });

      if (!!data.errors) {
        this.setServerErrors(data.errors);
      }
      else {
        this.setUnsubmited();
      }

      if (!!this.afterSubmit) {
        this.afterSubmit(data);
      }
    });
  }

  disableSubmit = () => {
    if (!this.state.submitDisabled) {
      this.setState({ submitDisabled: true });
    }
  }

  enableSubmit = () => {
    if (!!this.state.submitDisabled) {
      this.setState({ submitDisabled: false });
    }
  }

  get apiUrl() {
    return this.props.api_url || this.state.apiUrl;
  }

  checkValid = (key, validators) => {
    if (this.state.serverErrors[key]) {
      return this.state.serverErrors[key]
    }
    for (let validator of validators) {
      const msg = validator(this.state, this.props);
      if (!!msg) {
        return msg
      }
    }
  }

  get clientValidationMessages() {
    return Object.keys(this.validators).reduce((msgs, key) => {
      let msg;
      if (Array.isArray(this.validators[key])) {
        msg = this.checkValid(key, this.validators[key])
      }
      else {
        msg = this.validators[key](this.state, this.props) || this.state.serverErrors[key];
      }

      if (!!msg) {
        msgs[key] = msg;
      }

      return msgs;
    }, {});
  };

  get allValidationMessages() {
    return {
      ...this.clientValidationMessages,
      ...this.state.serverErrors,
    };
  }

  get validationMessages() {
    if (!this.state.submitted) {
      return {};
    }
    return this.allValidationMessages
  }

  get isFormValid() {
    return !Object.keys(this.allValidationMessages).length;
  }

  get serializedItem() {
    return this.state.item;
  }

  get formFooter() {
    return null;
  }
}

import React, { Fragment } from 'react';
import { CommonList } from 'Components';
import { toMoneyFormat } from 'Helpers/toMoneyFormat';
export class Donations extends CommonList {
  allMemberships = this.props.filters.memberships.map(({ id }) => id);
  allUsers = this.props.filters.users.map(({ id }) => id);
  allTournaments = this.props.filters.tournaments.map(({ id }) => id);
  state = {
    ...this.state,
    filter: {
      items: 'all',
      users: 'all',
      tournaments: 'all',
    }
  }


  get addons() {
    return (
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <select className="input-group__input" value={this.state.filter.items}
          onChange={this.handleChangeMbershipFilter}>
          <option value='all'>All Memberships</option>
          {this.props.filters.memberships.map(({ name, id }) => (
            <option value={id} key={id}>{name}</option>
          ))}
        </select>
        <select className="input-group__input" value={this.state.filter.users}
          onChange={this.handleChangeUserFilter}>
          <option value='all'>All Users</option>
          {this.props.filters.users.map(({ name, id }) => (
            <option value={id} key={id}>{name}</option>
          ))}
        </select>
        <select className="input-group__input" value={this.state.filter.tournaments}
          onChange={this.handleChangeTournamentFilter}>
          <option value='all'>All Tournaments</option>
          {this.props.filters.tournaments.map(({ name, id }) => (
            <option value={id} key={id}>{name}</option>
          ))}
        </select>
      </div>
    );
  }

  handleChangeMbershipFilter = ({ target }) => {
    if (target.value === 'all') {
      this.handleChangeFilter('items', this.allMemberships);
    }
    this.handleChangeFilter('items', target.value);
  }

  handleChangeUserFilter = ({ target }) => {
    if (target.value === 'all') {
      this.handleChangeFilter('users', this.allUsers);
    }
    this.handleChangeFilter('users', target.value);
  }

  handleChangeTournamentFilter = ({ target }) => {
    if (target.value === 'all') {
      this.handleChangeFilter('tournaments', this.allTournaments);
    }
    this.handleChangeFilter('tournaments', target.value);
  }

  fetchData = () => {
    const { items, users, tournaments } = this.state.filter;
    let filter = {
      items: items === 'all' ? this.allMemberships : [items],
      users: users === 'all' ? this.allUsers : [users],
      tournaments: tournaments === 'all' ? this.allTournaments : [tournaments],
    }
    super.fetchData({ filter });
  }
  get tableConfig() {
    return [
      {
        title: 'Name',
        accessor: 'name',
      },
      {
        title: 'Quantity',
        accessor: 'quantity',
      },
      {
        title: 'Price per item',
        accessor: 'price',
        cellFn: ({ item }) => toMoneyFormat(item.price),
      },
      {
        title: 'Amount',
        acessor: 'total_price',
        cellFn: ({ item }) => toMoneyFormat(item.total_price),
      },
      {
        title: 'Payment Link',
        accessor: 'payment_link',
        cellFn: ({ item }) =>
          item.payment_link && (
            <a className="link" href={item.payment_link} target="_blank">View Payment</a>
          )
      },
      {
        title: 'Donator',
        accessor: 'user_name',
        cellFn: ({ item, }) => (
          <a className="link" target="_blank" href={item.user_url}>
            {item.user_name}
          </a>
        ),
      },
      {
        title: 'Tournament',
        accessor: 'tournament'
      },
      {
        title: 'Teams',
        accessor: 'teams',
        cellFn: ({ item }) => item.teams.map(({ url, name, }) => (
          <Fragment key={url}>
            <a target="_blank" href={url} className="link">{name}</a>
            &nbsp;
          </Fragment>
        )),
      }
    ];
  }
}

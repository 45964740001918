import React from 'react';

export const UsersList = ({ users, userToString, onUserSelect }) => {
    return users.map((user) => (
        <li
            key={user.id}
            onClick={() => onUserSelect(user)}
            className="list-item list-item-user"
        >
            {userToString(user)}
        </li>
    ));
};

import React, { useEffect, useState } from 'react';
import { UsersList } from './UsersList';

export const UsersAutocomplete = ({
    setUser,
    inputValue,
    onInputChange,
    users,
    isLoading,
    debouncedSearch,
    isUserSelected,
    setIsUserSelected
}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const userToString = (user) => {
        return `${user.name} (${user.email})`;
    };

    const onUserSelect = (user) => {
        onInputChange(userToString(user));
        setUser(user.id);
        setShowDropdown(false);
        setIsUserSelected(true);
    };

    const onDropdownClose = (event) => {
        if (!event.target.closest('#UsersAutocomplate')) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', onDropdownClose);

        return () => {
            document.removeEventListener('click', onDropdownClose);
        };
    }, []);

    useEffect(() => {
        if (debouncedSearch) {
            setShowDropdown(true);
        }
    }, [debouncedSearch]);

    const onBlur = () => {
        if (!inputValue.trim()) {
            onInputChange('');
        }
    };

    return (
        <div
            id="UsersAutocomplate"
            className="user-autocomplete"
        >
            <input
                type="text"
                value={inputValue}
                onBlur={onBlur}
                onChange={(e) => onInputChange(e.target.value)}
                placeholder="Search users..."
                className="input-group__input"
            />
            {showDropdown && !isUserSelected && (
                <ul className="list">
                    {isLoading && !!debouncedSearch && (
                        <li className="list-item">Loading...</li>
                    )}
                    {!isLoading && !users.length && (
                        <li className="list-item">No users found</li>
                    )}
                    {!isLoading && (
                        <UsersList
                            users={users}
                            userToString={userToString}
                            onUserSelect={onUserSelect}
                        />
                    )}
                </ul>
            )}
        </div>
    );
};

import React from "react";

import { components } from "Common";
const { Button } = components;

export const ModalWindow = (props) => {
    return (
        <div className='modal'>
            <div className='modal__backdrop'>
                <div
                    className='modal__body'
                    style={{
                        textAlign: "center",
                        maxWidth: "80%",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "20px"
                    }}
                >
                    <h2>
                        To continue your work with application please update
                        your profile info
                    </h2>
                    <Button
                        type='link'
                        href={props.updateProfileUrl}
                        className='button-wrapper__button--auto-width'
                    >
                        Go to profile
                    </Button>
                </div>
            </div>
        </div>
    );
};

import React from 'react';
import { CommonList } from 'Components';
import { components, helpers } from 'Common';

const { IconButton } = components;
const { AJAX } = helpers;

export class Tournaments extends CommonList {
  handleCloneItem = ({ clone_api_url }) => {
    if (!!confirm('Are you sure?')) {
      AJAX.post(clone_api_url);
    }
  }

  handleLockedItem = ({ locked_url, locked }) => {
    if (!!confirm(`Are you sure you want to ${locked ? 'unlock' : 'lock'} the tournament?`)) {
      AJAX.post(locked_url);
    }
  }

  get actionsColumn() {
    return {
      title: 'Actions',
      accessor: 'actions',
      style: {
        width: '100px',
        textAlign: 'right',
      },
      cellFn: ({ item }) => {
        const handleDeleteClick = () => {
          this.handleRemoveItem(item);
        }

        const handleCloneTournament = () => {
          this.handleCloneItem(item);
        }

        const handleLockedTournament = () => {
          this.handleLockedItem(item);
        }

        const lockIcon = item.locked ? 'fas fa-lock' : 'fas fa-unlock';

        return (
          <div className="table__actions icon-buttons">
            <div className='button-tooltip'>
              <IconButton className="icon-buttons__button" icon={ lockIcon }
                onClick={handleLockedTournament} />
            <span className="button-tooltip__text">{ item.locked ? 'Unlock' : 'Lock' }</span>
            </div>
            <IconButton icon="fas fa-copy"
              onClick={handleCloneTournament} />
            <IconButton type="link" className="icon-buttons__button"
              href={`${this.props.url}/${item.id}/edit`} icon='fas fa-edit' />
            <IconButton className="icon-buttons__button" icon="fas fa-trash"
              onClick={handleDeleteClick} />
          </div>
        )
      }
    };
  }

  get tableConfig() {
    const { url } = this.props;
    return [
      {
        title: 'Tournament',
        accessor: 'name',
        sortable: true,
        cellFn: ({ item }) => (
          <a className="link" href={`${url}/${item.id}/edit`}>{item.name}</a>
        )
      },
      {
        title: 'Date',
        accessor: 'date',
        sortable: true,
      },
      this.actionsColumn,
    ];
  }
}

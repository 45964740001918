import React, { createRef, Fragment, } from 'react';
import { CommonForm, UsersAutocomplete } from 'Components';
import { checkRequired } from 'Helpers/validators';
import { components, helpers } from 'Common';
const { FormGroup, Button } = components;

const { AJAX } = helpers;

export class PurchaseForUserForm extends CommonForm {

  abortController = new AbortController();
  timeout = null;

  componentDidMount() {
    this.setState({
      isLoading: false,
      inputValue: '',
      debouncedValue: '',
      isUserSelected: false,
      users: []
    })
  }

  componentWillUnmount() {
    if(this.abortController) {
      this.abortController.abort();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  defaultItem = {
    user_id: ''
  }

  validators = {
    user_id: [checkRequired('user_id')],
  }

  get serializedItem() {
    const { user_id } = this.state.item;
    const { purchased_memberships, purchased_shirts } = this.props
    return {
      order: {
        user_id: Number(user_id),
        paid: true,
        purchased_items: [
          ...purchased_memberships,
          ...purchased_shirts,
        ]
      }
    }
  }

  onInputChange = (value) => {
    if(this.state.isUserSelected) {
      this.setState({isUserSelected: false})
    }

    this.setState({ inputValue: value });

    if(this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.setState({ debouncedValue: value });
      this.fetchUsers();
    }, 500);
  }

  setUsers = (users) => {
    this.setState({ users });
  }

  fetchUsers = () => {
    if (this.abortController.signal.aborted) {
      this.abortController = new AbortController();
    } else {
      this.abortController.abort();
    }

    this.setState({ isLoading: true });

     AJAX.get(this.props.usersUrl, {
       body: {
         page: 1,
         size_per_page: 10000,
         sort_column: 'name',
         sort_direction: 'asc',
         search: {
           value: this.state.debouncedValue
         }
       },
       signal: this.abortController.signal
     }).then((response => {
      if(response.data) {
        this.setUsers(response.data)
      }
     }))
      .catch((error) => {
        console.error(error);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  setUser = (id) => this.changeValue('user_id', id);

  render() {
    const { disabled } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className="card mb-20">
        <h2 className="card__title">Admin can assign memberships for user without payment in Stripe</h2>
        <p>Select memberships / t-shirts and quantity. Choose user and click <strong>assign</strong></p>
        <div className="input-with-button" style={{ maxWidth: '750px' }}>
          <FormGroup label="User" isRequired
            validationMessages={this.validationMessages.user_id} className="w-100">
              <UsersAutocomplete
                inputValue={this.state.inputValue}
                onInputChange={this.onInputChange}
                debouncedSearch={this.state.debouncedValue}
                users={this.state.users}
                setUser={this.setUser}
                fetchUsers={this.fetchUsers}
                isLoading={this.state.isLoading}
                isUserSelected={this.state.isUserSelected}
                setIsUserSelected={(isUserSelected) => this.setState({isUserSelected})}
              />
          </FormGroup>
          <div className="button-wrapper input-with-button__button-wrapper">
            <div className="input-group w-100 pl-20">
              <span className="input-group__label">&nbsp;</span>
              <Button type="submit" className="button-wrapper__button--sm_height"
                disabled={disabled}>
                assign
              </Button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

import { getToken, } from '../dom/index';
import { toQueryString } from '../url';

export class AJAX {
  static defaultParams = {
    headers: {
      'Accept': 'application/json',
      ['X-CSRF-Token']: getToken().authenticity_token,
    },
    method: 'GET',
    cache: 'default',
  }

  static getUrl(url, searchParams) {
    let resUrl = url;
    if (!!searchParams) {
      const paramsStr = toQueryString(searchParams);
      if (!!paramsStr.length) {
        resUrl = `${resUrl}?${paramsStr}`;
      }
    }
    return resUrl;
  }

  static sendRequest(url, { searchParams, notifyOnFailedResponse, notifyOnSuccesResponse, ...params }) {
    let headers = {
      ...AJAX.defaultParams.headers,
      ...params.headers,
    };
    let controller = new AbortController();
    let signal = controller.signal;
    if (!!params.body && !(params.body instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
    }
    const request = new Promise((resolve, reject) => {
      fetch(AJAX.getUrl(url, searchParams), { ...AJAX.defaultParams, ...params, signal, headers })
        .then((res) => {
          if (!res.ok) {
            return res.text().then((text) => {
              const message = text ? JSON.parse(text).message : text;
  
              if (!!notifyOnFailedResponse) {
                notifyOnFailedResponse(res.status, message);
              }
              return resolve({ status: res.status, ...data });
            });
          }
  
          return res.json().then((data) => {
            return data;
          });
        })
        .then(res => {
          if (res.redirect) {
            location.href = res.redirect;
          }
  
          if (!!res.flash_type && !!res.flash_message) {
            if (!!notifyOnSuccesResponse) {
              notifyOnSuccesResponse({ type: res.flash_type, text: res.flash_message });
            }
          }
          resolve(res);
        })
        .catch((err) => {
          console.log('There has been a problem with your fetch operation: ' + err.message);
        });
    });
  
    request.abort = controller.abort.bind(controller);
    return request;
  }

  static get(url, additionalParams = {}) {
    const { body, ...params } = additionalParams;
    return AJAX.sendRequest(url, { ...params, searchParams: body, method: 'GET' });
  }

  static post(url, params = {}) {
    return AJAX.sendRequest(url, {
      ...params,
      body: params.body instanceof FormData ? params.body : JSON.stringify(params.body),
      method: 'POST'
    }
    );
  }

  static put(url, params = {}) {
    return AJAX.sendRequest(url, {
      ...params,
      body: params.body instanceof FormData ? params.body : JSON.stringify(params.body),
      method: 'PUT'
    });
  }

  static delete(url, params) {
    return AJAX.sendRequest(url, { ...params, method: 'DELETE' });
  }
}
